<template>
  <b-card>
    <b-modal hide-footer centered size="lg" v-model="modalTemplate">
      <b-card centered title="Errores">
        <b-row>
          <b-col md="12" class="mt-1">
            <b-table responsive="sm" :items="tempResult" striped hover> </b-table>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>

    <hr />
    <b-card-title>{{ story.name }}</b-card-title>
    <hr />

    <b-form-group label="Idiomas de la página">
      <b-form-tags v-model="selectedLang" size="lg" add-on-change no-outer-focus>
        <template v-slot="{ tags, inputAttrs, inputHandlers, disabled }">
          <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-1">
            <li v-for="tag in tags" :key="tag" class="list-inline-item">
              <b-form-tag :title="tag" :disabled="disabled" variant="primary" @remove="removeLang(tag)">
                {{ getLanguageName(tag) }}
              </b-form-tag>
            </li>
          </ul>
          <b-form-select
            v-bind="inputAttrs"
            :disabled="disabled || availableOptions.length === 0"
            :options="availableOptions"
            value-field="iso"
            text-field="name"
            v-on="inputHandlers"
          >
            <template v-slot:first>
              <option disabled value="">Seleccioná los idiomas...</option>
            </template>
          </b-form-select>
        </template>
      </b-form-tags>
    </b-form-group>

    <b-form-group label="Páginas">
      <div>
        <b-form-tags v-model="selectedPages" no-outer-focus>
          <template #default="{ tags, inputAttrs, inputHandlers, addTag }">
            <b-input-group aria-controls="my-custom-tags-list">
              <input v-bind="inputAttrs" placeholder="Nueva Página - Presone Agregar" class="form-control" v-on="inputHandlers" />
              <b-input-group-append>
                <b-button :disabled="$apiCall.state" variant="primary" @click="addTag()"> + </b-button>
              </b-input-group-append>
            </b-input-group>
            <ul
              id="my-custom-tags-list"
              class="list-unstyled d-inline-flex flex-wrap mb-0"
              aria-live="polite"
              aria-atomic="false"
              aria-relevant="additions removals"
            >
              <b-card
                v-for="tag in tags"
                :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                :key="tag"
                tag="li"
                class="shadow-none border mt-1 mr-1 mb-0"
                body-class="py-1 pr-2 text-nowrap"
              >
                <strong>{{ tag }}</strong>
                <b-button
                  variant="link"
                  size="sm"
                  :disabled="$apiCall.state"
                  :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`"
                  class="py-0"
                  @click="deletePage(tag)"
                >
                  borrar
                </b-button>
              </b-card>
            </ul>
          </template>
        </b-form-tags>
      </div>
    </b-form-group>

    <app-collapse accordion type="margin">
      <app-collapse-item title="Opciones de Traducción">
        <b-card v-for="page in story.page" :key="page.name">
          <b-col>
            {{ page.name }}
          </b-col>
          <b-col class="mt-1">
            <b-form-checkbox
              v-for="content in page.content"
              v-model="content.autoTranslate"
              :disabled="content.block === 'es'"
              :key="content.block"
              :checked="content.autoTranslate"
              :name="content.block"
              switch
              inline
            >
              {{ content.block }}
            </b-form-checkbox>
          </b-col>
        </b-card>
      </app-collapse-item>
    </app-collapse>

    <app-collapse accordion type="margin">
      <app-collapse-item title="Respaldos y Recuperación">
        <b-row>
          <b-table responsive="sm" :items="getListFormated" striped hover>
            <template #cell(id)="data">
              <b-button :disabled="$apiCall.state" block variant="danger" @click="recovery(data.value)"> Recuperar </b-button>
              <b-button :disabled="$apiCall.state" block variant="outline-info" @click="download(data.value)"> Descargar </b-button>
            </template>
          </b-table>
        </b-row>
        <b-row>
          <b-col md="6" class="mt-1">
            <b-button :disabled="$apiCall.state" block variant="info" @click="backup()"> Respaldar </b-button>
          </b-col>
          <b-col md="6" class="mt-1">
            <b-form-file
              v-model="fileBackup"
              placeholder="Cargar Respaldo..."
              drop-placeholder="Arrastrar y Soltar aqui 📂"
              accept="application/json"
            />
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>

    <b-row class="mt-3">
      <b-col md="5" class="mt-1">
        <b-button :disabled="$apiCall.state" block variant="info" @click="publishAll(false)"> GUARDAR TODO </b-button>
      </b-col>
      <b-col md="5" class="mt-1">
        <b-button :disabled="$apiCall.state" block variant="danger" @click="publishAll(true)"> PUBLICAR </b-button>
      </b-col>
      <b-col md="2">
        <b-button :disabled="$apiCall.state" class="mt-1" block variant="primary" type="button" @click.prevent="setTemplate">
          <feather-icon icon="BookmarkIcon" />
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import {
  BTable,
  BFormTags,
  BFormTag,
  BFormGroup,
  BFormSelect,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox,
  BModal
} from 'bootstrap-vue'
import { getLocalDate, getTimeAgo } from '@/util/localDate'
import { mapState } from 'vuex'

export default {
  components: {
    BTable,
    BFormTags,
    BFormGroup,
    BFormSelect,
    BFormTag,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    BFormFile,
    BFormCheckbox,
    BModal
  },
  data: () => ({
    selectedLang: [],
    tempResult: [],
    modalTemplate: false,
    options: [
      {
        name: 'Español',
        iso: 'es'
      },
      {
        name: 'English',
        iso: 'en'
      },
      {
        name: 'German',
        iso: 'de'
      },
      {
        name: 'Italiano',
        iso: 'it'
      },
      {
        name: 'Français',
        iso: 'fr'
      },
      {
        name: '中国人',
        iso: 'zh'
      }
    ],
    selectedPages: [],
    fileBackup: null
  }),

  watch: {
    fileBackup(file) {
      const self = this
      var blob = new Blob([file], { type: 'application/json' })
      const reader = new FileReader()

      reader.onload = function () {
        const data = JSON.parse(this.result)
        self.$store
          .dispatch('storyStore/setBackup', { story: data.story, comentario: data.comentario + '(Importado)' || 'Respaldo Importado' })
          .then(() =>
            self.$swal({
              icon: 'success',
              title: 'Respaldo Importado',
              text: 'Las páginas fueron respaldadas.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          )
          .catch(() =>
            self.$swal({
              icon: 'error',
              title: 'Error',
              text: 'Error al cargar el respaldo',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          )
      }

      reader.readAsText(blob)
    },
    selectedLang(value) {
      const newLang = value.find(lang => this.story.lang.indexOf(lang) === -1)

      if (newLang && newLang !== 'es') this.$store.dispatch('storyStore/addLang', newLang)
    },
    selectedPages(value) {
      const newPage = value.find(page => this.getPages.indexOf(page) === -1)
      if (newPage && newPage !== 'home') {
        // copia el content del home, con los valores vacios
        return this.$store.dispatch('storyStore/addPage', {
          name: newPage,
          content: this.story.page[0].content.map(cont => ({
            ...cont,
            element: []
          }))
        })
      }
    }
  },
  computed: {
    ...mapState({
      story: state => state.storyStore.story,
      bkpList: state => state.storyStore.bkpList
    }),
    getListFormated() {
      return this.bkpList.map(e => ({
        id: e.date,
        comentario: e.comentario || '-',
        respaldo: `${this.toLocalDate(e.date)} - ${this.toTimeAgo(e.date)}`,
        story: e.story.name,
        lang: e.story.lang,
        pagina: e.story.page.reduce((acc, p) => [...acc, p.name], [])
      }))
    },
    getPages() {
      return this.story?.page.map(e => e.name) || []
    },
    getLanguageName() {
      return iso => this.options.find(opt => opt.iso === iso).name
    },
    availableOptions() {
      if (this.story.lang) return this.options.filter(opt => this.story.lang.indexOf(opt.iso) === -1)
      else return []
    }
  },
  methods: {
    setValues() {
      this.selectedLang = this.story?.lang || []
      this.selectedPages = this.getPages
    },
    toLocalDate(t) {
      return getLocalDate(t)
    },
    toTimeAgo(t) {
      return getTimeAgo(t)
    },
    recovery(oldDate) {
      const oldStory = this.bkpList.find(data => data.date === oldDate)
      if (oldStory)
        this.$swal({
          title: 'Recuperar la versión anterior?',
          text: 'Después de recuperar, debés publicar cada página para hacer efectiva la recuperación 😉',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sí, Recuperar!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            // carga lang y page de story con oldStory
            this.$store.commit('storyStore/SET_PAGE_LANG', oldStory.story)
            this.setValues()
            this.$swal({
              icon: 'success',
              title: 'Datos recuperados!',
              text: 'Las páginas fueron recuperadas a su versión anterior.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          }
        })
    },
    backup() {
      this.$swal({
        title: 'Agrega un comentario de respaldo',
        input: 'text',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Respaldar'
        // showLoaderOnConfirm: true
      }).then(result => {
        if (result.isConfirmed)
          this.$store.dispatch('storyStore/setBackup', { story: this.story, comentario: result.value || 'Respaldo Manual' }).then(() =>
            this.$swal({
              icon: 'success',
              title: 'Respaldo Completado!',
              text: 'Las páginas fueron respaldadas.',
              customClass: {
                confirmButton: 'btn btn-success'
              }
            })
          )
      })
    },
    download(val) {
      const selbak = this.bkpList.find(e => e.date === val)
      var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(selbak))

      var a = document.createElement('a')
      a.href = 'data:' + dataStr
      a.download = `story-${selbak.comentario}-(${val}).json`
      document.body.appendChild(a)
      a.click()
    },
    setTemplate() {
      this.$swal({
        title: 'Template',
        text: `Story: ${this.$session.story.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, crear',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.story.template = this.getTemplate()
          this.$noti.success(`Template creado!! 📖`)
        }
      })
    },
    getTemplate() {
      const template = this.story.page.map(page => ({
        name: page.name,
        content: {
          element: page.content
            .find(content => content.block === 'es')
            .element.map(el => ({
              field: el.field,
              type: el.type,
              groupValue: el.groupValue
                ? el.groupValue.map(gv => gv.reduce((acc, fg) => (fg.field !== 'IMG' ? [...acc, { field: fg.field, type: fg.type }] : acc), []))[0]
                : []
            }))
        },
        images: page.images
      }))

      return JSON.stringify(template)
    },
    testTemplate() {
      const template = JSON.parse(this.story.template)

      return template.reduce((acc, page) => {
        const sp = this.story.page.find(s => s.name === page.name)
        const el = page.content.element.reduce((ecc, el) => {
          if (!sp) acc.push({ page: page.name, field: '', type: '', error: 'Página Requerida' })
          else {
            const { element } = sp.content.find(pp => pp.block === 'es')
            const diff = element.reduce((err, ef) => {
              if (ef.field === el.field && ef.type === el.type) {
                err = true
                if (el.type === 'GROUP') {
                  if (el.groupValue)
                    el.groupValue.forEach(gve => {
                      const gvfield = !ef.groupValue
                        ? false
                        : ef.groupValue.reduce((eeg, rees, i) => {
                            let existe = false
                            rees.forEach(gg => {
                              if (gve.field === 'IMG' || (gg.field === gve.field && gg.type === gve.type)) existe = true
                            })
                            if (!existe)
                              eeg.push({ page: page.name, field: `${el.field}[ ${i + 1} ]↔️${gve.field}`, type: gve.type, error: 'Requerido' })

                            return eeg
                          }, [])

                      if (gvfield.length > 0) acc.push(...gvfield)
                    })
                }
              }

              return err
            }, false)
            if (!diff) acc.push({ page: page.name, field: el.field, type: el.type, error: 'Requerido' })
          }

          return ecc
        }, [])

        if (el.length > 0) acc.push(el)

        // img
        const img = page.images.reduce((icc, img) => {
          const diffImg = sp.images.reduce((mcc, pic) => {
            if (pic.field === img.field) mcc = true

            return mcc
          }, false)
          if (!diffImg) icc.push({ page: page.name, field: img.field, type: 'IMAGE', error: 'Requerido' })

          return icc
        }, [])

        if (img.length > 0) acc.push(...img)

        return acc
      }, [])
    },
    testStory() {
      const template = JSON.parse(this.story.template)

      return this.story.page.reduce((acc, page) => {
        const content = page.content.find(b => b.block === 'es')
        const templatePage = template.find(s => s.name === page.name)
        const elem = content.element.reduce((ecc, el) => {
          if (!templatePage) ecc.push({ page: page.name, field: '', type: '', error: 'La página no existe' })
          else {
            const diff = templatePage.content.element.reduce((err, ef) => {
              if (ef.field === el.field && ef.type === el.type) {
                err = true

                if (el.type === 'GROUP') {
                  const aelem = el.groupValue
                  if (aelem)
                    aelem.forEach((elem, i) => {
                      elem.forEach(gve => {
                        const gvfield = !ef.groupValue
                          ? false
                          : ef.groupValue.reduce((eeg, rees) => {
                              if (gve.field === 'IMG' || (rees.field === gve.field && rees.type === gve.type)) eeg = true

                              return eeg
                            }, false)

                        if (!gvfield) acc.push({ page: page.name, field: `${el.field}[ ${i + 1} ]↔️${gve.field}`, type: gve.type, error: 'No Existe' })
                      })
                    })
                }
              }

              return err
            }, false)
            if (!diff) ecc.push({ page: page.name, field: el.field, type: el.type, error: 'No Existe' })
          }
          return ecc
        }, [])

        if (elem.length > 0) acc.push(...elem)

        // img
        const img = page.images.reduce((icc, img) => {
          const diffImg = templatePage.images.reduce((mcc, pic) => {
            if (pic.field === img.field) mcc = true

            return mcc
          }, false)
          if (!diffImg) icc.push({ page: page.name, field: img.field, type: 'IMAGE', error: 'No Existe' })

          return icc
        }, [])

        if (img.length > 0) acc.push(...img)

        return acc
      }, [])
    },
    publishAll(publishToProduction) {
      if (!this.story.template)
        return this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'No hay template',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })

      const self = this
      this.$swal({
        title: `${publishToProduction ? 'Publicar' : 'Guardar'} todas las páginas`,
        text: publishToProduction ? 'Esta acción publicará todas las páginas y lenguajes al sitio princial' : '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Sí, ${publishToProduction ? 'Publicar' : 'Guardar'}!`,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false,
        showLoaderOnConfirm: true,
        preConfirm() {
          // recorre la pagina buscando los elementos definidos en el template
          const requerido = self.testTemplate()
          // recorre el story page, buscando elementos sobrantes
          const residuo = self.testStory()

          self.tempResult = [...requerido, ...residuo]

          if (self.tempResult.length > 0)
            return self.$swal({
              icon: 'error',
              title: 'Error',
              text: 'Hay campos con error',
              customClass: {
                confirmButton: 'btn btn-success'
              },
              preConfirm() {
                self.modalTemplate = true
              }
            })

          return self.$store
            .dispatch('storyStore/publishAll', { story: self.story, publishToProduction })
            .then(() =>
              self.$swal({
                icon: 'success',
                title: publishToProduction ? 'Páginas publicadas 🚀' : 'Páginas guardadas',
                text: publishToProduction ? 'Revisá la página principal para comprobar el resultado.' : '',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
            .catch(err =>
              self.$swal({
                icon: 'error',
                title: 'Error',
                text: err.toString(),
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              })
            )
        }
      })
    },
    removeLang(delLang) {
      if (delLang === 'es') return this.$noti.error('El lenguaje ESPAÑOL (es) no se puede eliminar.')
      const self = this
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos el lenguaje: ${delLang}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          self.story.page = self.story.page.reduce(
            (acc, page) => [...acc, { name: page.name, content: page.content.filter(({ block }) => block !== delLang) }],
            []
          )
          this.story.lang = this.story.lang.filter(f => f !== delLang)
          this.setValues()
        }
      })
    },
    deletePage(p) {
      if (p === 'home') return this.$noti.error('La página HOME no se puede eliminar.')
      const self = this
      this.$swal({
        title: 'Eliminar?',
        text: `Eliminaremos la página: ${p}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí, eliminar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          self.story.page = self.story.page.filter(e => e.name !== p)
          this.setValues()
        }
      })
    }
  }
}
</script>
